import {css, html, LitElement} from "lit";
import {pxToRem} from "../styles";

export class WlModal extends LitElement {
    private _open: boolean;
    public fullscreen: boolean;
    public noScroll: boolean;
    public title: string;
    private readonly centered: boolean;

    public constructor() {
        super();
        this._open = false;
        this.fullscreen = false;
        this.title = "";
        this.noScroll = false;
        this.centered = false;
    }

    static get properties() {
        return {
            _open: {type: Boolean, state: true},
            fullscreen: {type: Boolean},
            title: {type: String},
            noScroll: {type: Boolean, attribute: "no-scroll"},
            centered: {type: Boolean, attribute: "centered"}
        }
    }

    static get styles() {
        return css`
            .modal {
                display: none;
                z-index: var(--wl-modal-z-index, 9999);
            }

            .modal.open {
                display: flex;
                align-items: center;
                justify-content: center;
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }

            .overlay {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: var(--wl-overlay-background-color, rgba(0, 0, 0, 0.3));
            }


            .panel {
                background-color: var(--wl-modal-background-color, white);
                display: flex;
                flex-direction: column;
                z-index: 2;
                width: 500px;
                max-width: calc(100% - 10px);
                max-height: calc(100% - 200px);
                border-radius: var(--wl-modal-border-radius, ${pxToRem(4)});
                box-shadow: var(--wl-dropdown-content-shadow, none);
            }

            .fullscreen .panel {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                border-radius: 0;
            }

            .header {
                display: flex;
                justify-content: space-between;

                padding: var(--wl-modal-header-padding-top, var(--wl-modal-padding, ${pxToRem(24)})) var(--wl-modal-padding, ${pxToRem(24)}) ${pxToRem(10)} var(--wl-modal-padding, ${pxToRem(24)});
                font-size: var(--wl-modal-header-font-size, ${pxToRem(20)});
                font-weight: 600;
            }

            .title {
                margin: 0;
                padding: ${pxToRem(7)} 0;
            }

            .close {
                cursor: pointer;
                width: ${pxToRem(32)};
                height: ${pxToRem(32)};
                padding: ${pxToRem(5)};
            }

            .body {
                padding: var(--wl-modal-body-top-padding, ${pxToRem(10)}) var(--wl-modal-padding, ${pxToRem(24)});
                flex: 1 1 auto;
                display: block;
                overflow: auto;
                -webkit-overflow-scrolling: touch;
            }

            .centered .body {
                display: flex;
                justify-content: center;
            }

            .no-scroll .body {
                overflow: hidden;
            }

            .footer {
                padding-top: ${pxToRem(10)};
                padding-right: var(--wl-modal-footer-padding, var(--wl-modal-padding, ${pxToRem(24)}));
                padding-bottom: var(--wl-modal-footer-bottom-padding, var(--wl-modal-footer-padding, var(--wl-modal-padding, ${pxToRem(24)})));
                padding-left: var(--wl-modal-footer-padding, var(--wl-modal-padding, ${pxToRem(24)}));
            }
        }
        `
    }

    render() {
        return html`
            <div class="modal ${this._open ? "open" : ""} ${this.fullscreen ? "fullscreen" : ""} ${this.noScroll ? "no-scroll" : ""} ${this.centered ? "centered" : ""}">
                <div class=overlay @click="${this.close}">
                </div>
                <div class="panel">
                    <header class="header" part="header">
                        <p class="title" part="header-title">${this.title}</p>
                        <wl-icon part="cross" class="close" icon="cross" @click="${this.close}"></wl-icon>
                    </header>
                    <div class="body" part="body">
                        <slot></slot>
                    </div>
                    <footer class="footer" part="footer">
                        <slot name="footer"></slot>
                    </footer>
                </div>
            </div>
        `

    }

    open() {
        document.addEventListener('keydown', this._closeOnEscape.bind(this));
        document.querySelector("body")!!.style.overflow = "hidden";
        this._open = true;
    }

    close() {
        document.removeEventListener('keydown', this._closeOnEscape.bind(this));
        document.querySelectorAll("body")!!.forEach((body) => body.style.overflow = "auto");
        this._open = false;
    }

    private _closeOnEscape(event: KeyboardEvent) {
        if (event.code == "Escape") {
            this.close();
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        document.removeEventListener('keydown', this._closeOnEscape.bind(this));
    }
}
